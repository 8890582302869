/* vietnamese */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75cde960c2842862-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ff84563a719c397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anton_6fce18';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4c7655c11f7bd97b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anton_Fallback_6fce18';src: local("Arial");ascent-override: 129.70%;descent-override: 36.29%;line-gap-override: 0.00%;size-adjust: 90.69%
}.__className_6fce18 {font-family: '__Anton_6fce18', '__Anton_Fallback_6fce18';font-weight: 400;font-style: normal
}.__variable_6fce18 {--font-Anton: '__Anton_6fce18', '__Anton_Fallback_6fce18'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Manrope_e05e30';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/39db0bbaf4fef0ef-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Manrope_e05e30';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c675bb60facdb27e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Manrope_e05e30';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/60f45e67c3ff03d3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Manrope_e05e30';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/03025e3a642d5e24-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Manrope_e05e30';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/639d8fcc6849ff99-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Manrope_e05e30';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cd19368c2f67321a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Manrope_Fallback_e05e30';src: local("Arial");ascent-override: 103.31%;descent-override: 29.07%;line-gap-override: 0.00%;size-adjust: 103.19%
}.__className_e05e30 {font-family: '__Manrope_e05e30', '__Manrope_Fallback_e05e30';font-weight: 400;font-style: normal
}.__variable_e05e30 {--font-Manrope: '__Manrope_e05e30', '__Manrope_Fallback_e05e30'
}

